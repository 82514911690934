import './index.scss'
import bootstrap from "bootstrap/dist/js/bootstrap.bundle"
import "bootstrap-icons/font/bootstrap-icons.css"
import "flatpickr/dist/flatpickr.css"
import "flatpickr/dist/flatpickr"

// Define in french the default locale for the datepicker
const French = require("flatpickr/dist/l10n/fr.js").default.fr
flatpickr.localize(French)

window.addEventListener("DOMContentLoaded", () => {
  // Detect Safari
  const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
  if (isSafari) {
    document.body.classList.add('safari')
  }
  
  // Find and init select dropdowns
  document.querySelectorAll('.dropdown.select').forEach((item) => {
    const activeItem = item.querySelector('.dropdown-item.active')
    const label = item.querySelector('.dropdown-toggle')
    const input = document.createElement('input')
    input.setAttribute('text', 'text') 
    input.setAttribute('required', 'required') 
    input.name = item.dataset.name
    input.classList.add('sr-only') 

    if (activeItem) {
      label.textContent = input.value = activeItem.dataset.value
    }

    item.appendChild(input)

    item.querySelectorAll('.dropdown-item').forEach((option) => {
      option.addEventListener('click', function () {
        label.textContent = input.value = option.dataset.value
        item.querySelector('.dropdown-item.active').classList.remove('active')
        option.classList.add('active')
      }, false)
    })
  })

  // Init tooltips
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, {
      html: true
    })
  })
})